<template>
  <div>

    <el-card>

      <!-- 搜索框区域 -->
      <el-row :gutter="20">
        <el-col :span="19">
          <div>
            <el-input placeholder="手机关键字" v-model="queryInfo.phonenumber" clearable @clear="query">
              <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <el-table :data="userList" border stripe table-layout='auto' class="tableAuto">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column width="100px" label="用户头像" prop="WxAvatarurl">
          <template slot-scope="scope">
            <img style="width:50px;height:50px;border-radius: 50%;" :src="rootUrl+scope.row.WxAvatarurl" />
          </template>
        </el-table-column>

        <el-table-column :width="flexWidth('WxName',userList,'',20)" label="微信名称" prop="WxName"></el-table-column>
        <el-table-column :width="flexWidth('PhoneNumber',userList,'',20)" label="手机号码" prop="PhoneNumber"></el-table-column>
        <el-table-column width="100px" label="社保用户" prop="IsSocailUser"></el-table-column>
        <el-table-column :width="flexWidth('UserGrade',userList,'',20)" label="用户级别" prop="UserGrade"></el-table-column>
        <el-table-column :width="flexWidth('UpWxName',userList,'',20)" label="上级用户" prop="UpWxName"></el-table-column>
        <el-table-column class="button_column" label="操作" width="350px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="showAuthDialog(scope.row.id)">绑定</el-button>
            <el-button type="warning" size="mini" @click="showEditDialog(scope.row.id)">详细</el-button>
            <el-button type="primary" size="mini" @click="showAddDialog(scope.row.id)">级别</el-button>
            <el-button type="primary" v-if="scope.row.IsSocailUser=='1'" size="mini" @click="showUploadDialog(scope.row.NewsId)">上传</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination v-if="!isMobile" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalRecordCount">
      </el-pagination>
      <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total,sizes,prev, pager, next" :total="totalRecordCount">
      </el-pagination>

      <el-dialog title="用户信息" :visible.sync="editDialogVisible" width="100%" :close="editDialogClose" :close-on-click-modal="false">
        <el-form :model="editDialogForm" label-width="100px" class="dialog_form">
          <el-form-item label="微信名称" prop="loginName">
            <el-input v-model="editDialogForm.CurWxUser.WxName" disabled></el-input>
          </el-form-item>
          <el-form-item label="注册时间" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.RegDateTime" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.PhoneNumber" disabled></el-input>
          </el-form-item>
          <el-form-item label="注册省份" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.Province" disabled></el-input>
          </el-form-item>
          <el-form-item label="注册城市" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.City" disabled></el-input>
          </el-form-item>
          <el-form-item label="注册县区" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.County" disabled></el-input>
          </el-form-item>
          <el-form-item label="抵扣积分" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.DeductPoint" disabled></el-input>
          </el-form-item>
          <el-form-item label="赠送积分" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.GivePoint" disabled></el-input>
          </el-form-item>
          <el-form-item label="收益积分" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.CashoutPoint" disabled></el-input>
          </el-form-item>
          <el-form-item label="待转积分" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.FreezePoint" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户级别" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.UserGrade" disabled></el-input>
          </el-form-item>
          <el-form-item label="是否社保" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.IsSocailUser" disabled></el-input>
          </el-form-item>
          <el-form-item label="分享来源" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.ScanUserNewsId" disabled></el-input>
          </el-form-item>
          <el-form-item label="UP 用户" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.UpWxName" disabled></el-input>
          </el-form-item>
          <el-form-item label="UPUP用户" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.UpUpWxName" disabled></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClose">关 闭</el-button>
        </span>
      </el-dialog>

      <el-dialog title="级别改变" :visible.sync="addDialogVisible" width="100%" :close="addDialogClose" :close-on-click-modal="false">
        <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="微信名称" prop="loginName">
            <el-input v-model="addDialogForm.WxName" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户级别" prop="userName">
            <el-input v-model="addDialogForm.UserGrade" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="userName">
            <el-input v-model="addDialogForm.PhoneNumber" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户级别" :required="true">
            <el-select v-model="addDialogForm.SelectedUserGrade" class="form-select" clearable filterable placeholder="请选择用户级别">
              <el-option label="加盟商" value="加盟商"></el-option>
              <el-option label="业务经理" value="业务经理"></el-option>
              <el-option label="普通用户" value="普通用户"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="绑定分配" :visible.sync="authDialogVisible" width="100%" :close="authDialogClose" :close-on-click-modal="false">
        <el-form :model="authDialogForm" ref="authDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="" prop="loginName">
            <el-label style="color:#409eff;">当前微信用户</el-label>
          </el-form-item>
          <el-form-item label="微信名称" prop="loginName">
            <el-input v-model="authDialogForm.WxName" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户级别" prop="userName">
            <el-input v-model="authDialogForm.UserGrade" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="userName">
            <el-input v-model="authDialogForm.PhoneNumber" disabled></el-input>
          </el-form-item>
          <el-row :gutter="20" style="margin-top:20px;">
            <el-col :span="24">
              <div>
                <el-input placeholder="手机关键字" v-model="authDialogForm.key" clearable>
                  <el-button slot="append" icon="el-icon-search" @click="getUserGradeList"></el-button>
                </el-input>
              </div>
            </el-col>
          </el-row>
          <el-table :data="userGradeList" border stripe @row-click="rowClick">
            <el-table-column label="#" type="index"></el-table-column>

            <el-table-column :width="flexWidth('WxName',userList,'',20)" label="微信名称" prop="WxName"></el-table-column>
            <el-table-column :width="flexWidth('PhoneNumber',userList,'',20)" label="手机号码" prop="PhoneNumber"></el-table-column>
            <el-table-column :width="flexWidth('UserGrade',userList,'',20)" label="用户级别" prop="UserGrade"></el-table-column>
            <!-- <el-table-column class="button_column" label="操作" width="150px">
              <template slot-scope="scope">
                <el-button type="success" size="mini" @click="setUserGradeNewsId(scope.row.NewsId,scope.row.UpNewsId,scope.row.WxName,scope.row.PhoneNumber,scope.row.UserGrade)">选择</el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <el-form-item label="" prop="loginName">
            <el-label style="color:#409eff;">当前选择上级</el-label>
          </el-form-item>
          <el-form-item label="微信名称" prop="loginName" :required="true">
            <el-input v-model="authDialogForm.selectedWxName" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户级别" prop="userName" :required="true">
            <el-input v-model="authDialogForm.selectedUserGrade" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="userName" :required="true">
            <el-input v-model="authDialogForm.selectedPhoneNumber" disabled></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="authDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAuth">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="上传文件" :visible.sync="uploadDialogVisible" width="100%" :close="uploadDialogClose" :close-on-click-modal="false">
        <el-upload class="upload-demo" ref="upload" action="" :before-upload="beforeAvatarUpload" :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" accept=".jpg, .png" :auto-upload="true" list-type="picture-card">
          <el-button slot="trigger" size="small" type="primary">上传劳动合同</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <el-upload style="margin-top:20px;" class="upload-demo" ref="uploadBus" action="" :on-success="handleAvatarSuccessBus" :before-upload="beforeAvatarUploadBus" :on-preview="handlePreviewBus" :on-remove="handleRemoveBus" :file-list="fileListBus" accept=".jpg, .png" :auto-upload="true" list-type="picture-card">
          <el-button slot="trigger" size="small" type="primary">上传业务协议</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePreviewBus(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemoveBus(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </el-dialog>
      <el-dialog :visible.sync="dialogVisible"   modal-append-to-body='false' class="custom_width">
          <img style="width:100%;" :src="dialogImageUrl" alt="">
        </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rootUrl: '',
      userList: [],
      totalRecordCount: 0,
      queryInfo: {
        phonenumber: "",
        issocial: "",
        pagenum: 1,
        pagesize: 5,
      },
      addDialogVisible: false,
      editDialogVisible: false,
      authDialogVisible: false,
      addDialogForm: {
        WxName: "",
        PhoneNumber: "",
        NewsId: "",
        UserGrade: "",
        SelectedUserGrade: "",
      },
      editDialogForm: {
        CurWxUser: [],
      },
      authDialogForm: {
        WxName: "",
        PhoneNumber: "",
        UserGrade: "",
        NewsId: "",
        UpNewsId: "",
        UpUpNewsId: "",
        selectedWxName: "",
        selectedPhoneNumber: "",
        selectedUserGrade: "",
        key: "",
      },
      userGradeList: [],
      isMoblile: false,
      currentRow: null,

      uploadDialogVisible: false,
      uploadDialogForm: {
        newsid: '',
      },
      fileList: [],
      fileListBus: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    };
  },
  created() {
    this.getUserList();
    var isMobileTemp = window.sessionStorage.getItem("isMobile");
    if (isMobileTemp == 'true') {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.rootUrl = window.sessionStorage.getItem("rootUrl");
    console.log(this.rootUrl)
  },
  methods: {
    
    handleAvatarSuccess(res, file) {
      alert("bbb")
      console.log(res)
    },
    beforeAvatarUpload(file) {

      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
        return;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
        return;
      }
      //return (isJPG || isPNG) && isLt2M;
      this.uploadFile(file)
    },
    handleRemove(file, fileList) {
      //console.log(file, fileList);
      this.$confirm("您确实要删除吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.removeExe(file.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          this.getAgreementListByUser('劳动合同');
        });
    },
    async removeExe(id) {
      await this.$http.post("WxUser/deleteAgreementFile/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getAgreementListByUser('劳动合同');
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    handlePreview(file) {
      //console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async uploadFile(file) {
      let formData = new FormData()
      //let file = item.raw
      //console.log(item)
      formData.append('newsid', this.uploadDialogForm.newsid);
      formData.append('imgtype', '劳动合同')
      formData.append('file', file)
      await this.$http.post("WxUser/UploadAgreement/", formData, {
        'Content-type': 'multipart/form-data'
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getAgreementListByUser('劳动合同');
          //this.getAgreementListByUser('业务协议');
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    handleAvatarSuccessBus(res, file) {
      console.log(res)
    },
    beforeAvatarUploadBus(file) {

      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;
 
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
        return;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
        return;
      }
      //return (isJPG || isPNG) && isLt2M;
      this.uploadFileBus(file)
    },
    handleRemoveBus(file, fileList) {
      //console.log(file, fileList);
      this.$confirm("您确实要删除吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.removeExeBus(file.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          this.getAgreementListByUser('业务协议');
        });
    },
    async removeExeBus(id) {
      await this.$http.post("WxUser/deleteAgreementFile/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getAgreementListByUser('业务协议');
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    handlePreviewBus(file) {
      //console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async uploadFileBus(file) {
      let formData = new FormData()
      //let file = item.raw
      //console.log(item)
      formData.append('newsid', this.uploadDialogForm.newsid);
      formData.append('imgtype', '业务协议')
      formData.append('file', file)
      await this.$http.post("WxUser/UploadAgreement/", formData, {
        'Content-type': 'multipart/form-data'
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getAgreementListByUser('业务协议');
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    async getAgreementListByUser(type) {
      var paramVar = this.$qs.stringify({
        ImgType: type,
        WxUserNewsId: this.uploadDialogForm.newsid,
      });
      await this.$http.post("WxUser/getAgreementListBySocial", paramVar).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);

          if (res.data.Obj != null && res.data.Obj.length > 0) {
            if (type == '劳动合同') this.fileList.length = 0;
            if (type == '业务协议') this.fileListBus.length = 0;
            for (var i = 0; i < res.data.Obj.length; i++) {
              var tempObj = new Object();
              tempObj.id = res.data.Obj[i].id;
              tempObj.name = "";
              tempObj.url = this.rootUrl + res.data.Obj[i].ImgUrl;
              if (type == '劳动合同') this.fileList.push(tempObj);
              if (type == '业务协议') this.fileListBus.push(tempObj);

            }
          }
          //console.log(this.fileList)
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    uploadDialogClose() {
      this.uploadDialogVisible = false;
    },
    showUploadDialog(newsid) {
      this.uploadDialogForm.newsid = newsid;
      this.getAgreementListByUser('劳动合同');
      this.getAgreementListByUser('业务协议');
      this.uploadDialogVisible = true;

    },
    rowClick(row) {
      this.authDialogForm.UpNewsId = row.NewsId;
      this.authDialogForm.UpUpNewsId = row.UpNewsId;
      this.authDialogForm.selectedWxName = row.WxName;
      this.authDialogForm.selectedPhoneNumber = row.PhoneNumber;
      this.authDialogForm.selectedUserGrade = row.UserGrade;
    },
    /* 因为是返回的promise对象，所以用async及await */
    async getUserList() {
      //this.$http.post("User/GetUserList", this.queryInfo)
      var paramVar = this.$qs.stringify({
        phonenumber: this.queryInfo.phonenumber,
        issocial: this.queryInfo.issocial,
        pagenum: this.queryInfo.pagenum,
        pagesize: this.queryInfo.pagesize,
      });
      //await this.$http.get("User/GetUserList", { params: this.queryInfo }).then(
      await this.$http.post("WxUser/getListByPage", paramVar).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.userList = res.data.Obj;
          this.totalRecordCount = res.data.TotalCount;
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    change(e) {
      this.$forceUpdate();
    },
    query() {
      this.getUserList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getUserList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getUserList();
    },
    async getUserGradeList() {
      await this.$http.post("WxUser/GetList/", {
        phonenumber: this.authDialogForm.key,
        usergrade: '1'
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.userGradeList = res.data.Obj;
          this.authDialogForm.UpNewsId = "";
          this.authDialogForm.UpUpNewsId = "";
          this.authDialogForm.selectedWxName = "";
          this.authDialogForm.selectedPhoneNumber = "";
          this.authDialogForm.selectedUserGrade = "";
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    addDialogClose() {
      this.addDialogForm.roleId = "";
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    showAddDialog(id) {
      var user = null;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === id) user = this.userList[i];
      }
      this.addDialogForm.NewsId = user.NewsId;
      this.addDialogForm.WxName = user.WxName;
      this.addDialogForm.UserGrade = user.UserGrade;
      this.addDialogForm.PhoneNumber = user.PhoneNumber;
      this.addDialogVisible = true;
      this.addDialogForm.SelectedUserGrade = "";
    },
    saveAdd() {
      if (this.addDialogForm.SelectedUserGrade === "")
        return this.$message.error("请选择用户级别");
      if (this.addDialogForm.SelectedUserGrade == this.addDialogForm.UserGrade)
        return this.$message.error("级别相同，不需改变");
      this.submitAadd();
    },
    async submitAadd() {
      await this.$http.post("WxUser/updateWxUserGrade/", {
        usernewsid: this.addDialogForm.NewsId,
        usergrade: this.addDialogForm.SelectedUserGrade,
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.addDialogVisible = false;
          this.getUserList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    editDialogClose() {
      this.editDialogVisible = false;
    },
    showEditDialog(id) {
      var user = null;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === id) user = this.userList[i];
      }
      this.editDialogForm.CurWxUser = user;
      this.editDialogVisible = true;
    },
    showAuthDialog(id) {
      var user = null;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === id) user = this.userList[i];
      }
      this.authDialogForm.WxName = user.WxName;
      this.authDialogForm.PhoneNumber = user.PhoneNumber;
      this.authDialogForm.UserGrade = user.UserGrade;
      this.authDialogForm.NewsId = user.NewsId;
      this.authDialogVisible = true;
    },
    authDialogClose() {
      this.authDialogVisible = false;
    },
    saveAuth() {
      if (this.authDialogForm.UpNewsId === "")
        return this.$message.error("请选择绑定业务经理或加盟商");
      if (this.authDialogForm.UpNewsId == this.authDialogForm.NewsId)
        return this.$message.error("不能选择本人进行绑定");
      this.submitAuth();
    },
    async submitAuth() {
      await this.$http.post("WxUser/updateWxUserIsSocial/", {
        NewsId: this.authDialogForm.NewsId,
        UpNewsId: this.authDialogForm.UpNewsId,
        UpUpNewsId: this.authDialogForm.UpUpNewsId,
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.authDialogVisible = false;
          this.getUserList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    setUserGradeNewsId(upNewsId, upUpNewsId, wxName, phoneNumber, userGrade) {

      this.authDialogForm.UpNewsId = upNewsId;
      this.authDialogForm.UpUpNewsId = upUpNewsId;
      this.authDialogForm.selectedWxName = wxName;
      this.authDialogForm.selectedPhoneNumber = phoneNumber;
      this.authDialogForm.selectedUserGrade = userGrade;
    },
    flexWidth(prop, tableData, title, num = 0) {
      if (tableData.length === 0) {//表格没数据不做处理
        return;
      }
      let flexWidth = 0;//初始化表格列宽

      flexWidth = this.$common.flexWidth(prop, tableData, title, num);
      return flexWidth + 'px';
    },
  },
};
</script>

<style lang="less" scoped>
.el-table .cell {
  white-space: pre-line;
}
</style>