<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索框区域 -->
      <el-row :gutter="20">
        <el-col :span="15">
          <div>
            <el-input placeholder="标题关键字" v-model="queryInfo.query" clearable @clear="query">
              <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-button type="primary" @click="addDialogVisible = true">添加内容</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="dataList" border stripe table-layout='auto' class="tableAuto">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column :width="flexWidth('ContentName',dataList,'',20)" label="文档标题" prop="ContentName"></el-table-column>
        <el-table-column :width="flexWidth('AddDateTime',dataList,'',20)" label="添加时间" prop="AddDateTime"></el-table-column>
        <el-table-column width="150px" label="状态（启用/停用）" prop="IsUse">
          <!-- 作用域插槽，绑定状态 -->
          <template slot-scope="scope">
            <el-switch v-model="scope.row.IsUse" @change="useStateChanged(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="150px" label="置顶（启用/停用）" prop="IsUpShow">
          <!-- 作用域插槽，绑定状态 -->
          <template slot-scope="scope">
            <el-switch v-model="scope.row.IsUpShow" @change="upShowStateChanged(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column class="button_column" label="操作" width="350px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination v-if="!isMobile" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalRecordCount">
      </el-pagination>
      <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total,sizes,prev, pager, next" :total="totalRecordCount">
      </el-pagination>

      <el-dialog title="添加文档" :visible.sync="addDialogVisible" width="100%" :close="addDialogClose" :close-on-click-modal="false">
        <el-form label-position="top" :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="文档标题" prop="ContentName">
            <el-input v-model="addDialogForm.ContentName"></el-input>
          </el-form-item>
          <el-form-item label="文档内容" prop="ContentDesc">
            <div id='quillEditorQiniu'>
              <!-- 基于elementUi的上传组件 el-upload begin-->
              <el-upload class="avatar-uploader" :action="uploadImgUrl" :accept="'image/*,video/*'" :show-file-list="false" :on-success="uploadEditorSuccess" :on-error="uploadEditorError" :before-upload="beforeEditorUpload" :headers="headers">
              </el-upload>
              <!-- 基于elementUi的上传组件 el-upload end-->
              <quill-editor class="editor" v-model="addDialogForm.ContentDesc" ref="customQuillEditor" :options="editorOption">
              </quill-editor>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>

      </el-dialog>

      <el-dialog title="编辑文档" :visible.sync="editDialogVisible" width="100%" :close="editDialogClose" :close-on-click-modal="false">
        <el-form label-position="top" :model="editDialogForm" :rules="editDialogFormRules" ref="editDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="文档标题" prop="ContentName">
            <el-input v-model="editDialogForm.ContentName"></el-input>
          </el-form-item>
          <el-form-item label="文档内容" prop="ContentDesc">
            <div id='quillEditorQiniu'>
              <!-- 基于elementUi的上传组件 el-upload begin-->
              <el-upload class="avatar-uploader" :action="uploadImgUrl" :accept="'image/*,video/*'" :show-file-list="false" :on-success="uploadEditorSuccessEdit" :on-error="uploadEditorErrorEdit" :before-upload="beforeEditorUploadEdit" :headers="headers">
              </el-upload>
              <!-- 基于elementUi的上传组件 el-upload end-->
              <quill-editor class="editor" v-model="editDialogForm.ContentDesc" ref="customQuillEditorEdit" :options="editorOption">
              </quill-editor>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>

      </el-dialog>

    </el-card>
  </div>
</template>

<script>
// import * as Quill from 'quill'
// // 这里引入修改过的video模块并注册
// import Video from './video'
// Quill.register(Video, true)

//获取登录token，引入文件，如果只是简单测试，没有上传文件是否登录的限制的话，
//这个token可以不用获取，文件可以不引入，把上面对应的上传文件携带请求头  :headers="headers" 这个代码删掉即可
// import { getToken } from "@/utils/auth";

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
];
export default {
  data() {
    return {
      rootUrl: '',
      baseUrl: '',
      dataList: [],
      totalRecordCount: 0,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 5,
        // token: window.sessionStorage.getItem("token"),
        // loginname: window.sessionStorage.getItem("loginname"),
      },
      addDialogVisible: false,
      editDialogVisible: false,
      authDialogVisible: false,
      addDialogForm: {
        ContentName: "",
        ContentDesc: '',    //最终保存的内容
      },
      editDialogForm: {
        ContentName: "",
        ContentDesc: '',    //最终保存的内容
        id: 0,
      },
      isMoblile: false,

      addDialogFormRules: {
        ContentName: [
          { required: true, message: "请输入文档标题", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      editDialogFormRules: {
        ContentName: [
          { required: true, message: "请输入文档标题", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],

      },
      headers: {
        //Authorization: "Bearer " + getToken(),
        token: "4055F5C41E29B49D4CED1D9582B8C892"
      },
      uploadImgUrl: "Doc/UploadImg",
      uploadUrlPath: "没有文件上传",
      quillUpdateImg: false,
      content: '',    //最终保存的内容
      editorOption: {
        placeholder: '你想写什么？',
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          },
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                  document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
                } else {
                  this.quill.format('image', false);
                }
              },
              'video': function (value) {
                if (value) {
                  document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
                } else {
                  this.quill.format('video', false);
                }
              },
            }
          }
        }
      },
    };
  },
  created() {
    this.getDataList();
    var isMobileTemp = window.sessionStorage.getItem("isMobile");
    if (isMobileTemp == 'true') {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.rootUrl = window.sessionStorage.getItem("rootUrl");
    this.baseUrl = window.sessionStorage.getItem("baseUrl");
    this.uploadImgUrl = this.baseUrl + "Doc/UploadImg";
  },
  methods: {
    /* 因为是返回的promise对象，所以用async及await */
    async getDataList() {
      //this.$http.post("User/GetUserList", this.queryInfo)
      var paramVar = this.$qs.stringify({
        query: this.queryInfo.query,
        pagenum: this.queryInfo.pagenum,
        pagesize: this.queryInfo.pagesize,
      });
      //await this.$http.get("User/GetUserList", { params: this.queryInfo }).then(
      await this.$http.post("Doc/getInsuranceDocListByPage", paramVar).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.dataList = res.data.Obj;
          this.totalRecordCount = res.data.TotalCount;

          //获取列表数据之后，计算每列最大宽度
          //const columns = this.$common.calcColumnsWidth(this.tableHead, res.data.Obj);
          //this.tableHead = columns;
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    change(e) {
      this.$forceUpdate();
    },
    query() {
      this.getDataList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getDataList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getDataList();
    },
    /* 监听switch用户状态改变事件 */
    async upShowStateChanged(row) {
      await this.$http
        .post("Doc/UpdateUse/", {
          id: row.id,
          IsUpShow: row.IsUpShow,
        })
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              row.IsUpShow = !row.IsUpShow;
              return this.$message.error(res.data.Message);
            }
            this.$message.success("置顶状态更新成功");
          },
          (res) => {
            row.IsUpShow = !row.IsUpShow;
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    async useStateChanged(row) {
      await this.$http
        .post("Doc/UpdateUse/", {
          id: row.id,
          IsUse: row.IsUse,
        })
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              row.IsUse = !row.IsUse;
              return this.$message.error(res.data.Message);
            }
            this.$message.success("使用状态更新成功");
          },
          (res) => {
            row.IsUse = !row.IsUse;
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    addDialogClose() {
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    saveAdd() {
      this.$refs.addDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitAdd();
      });
    },
    async submitAdd() {
      console.log(this.addDialogForm);
      await this.$http.post("Doc/addObj/", this.addDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.addDialogFormRef.resetFields();
          this.addDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    editDialogClose() {
      this.editDialogVisible = false;
      this.$refs.editDialogFormRef.resetFields();
    },
    showEditDialog(id) {
      var obj = null;
      for (var i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].id === id) obj = this.dataList[i];
      }

      this.editDialogForm.ContentName = obj.ContentName;
      this.editDialogForm.ContentDesc = obj.ContentDesc;
      this.editDialogForm.id = id;
      this.editDialogVisible = true;
    },
    saveEdit() {
      this.$refs.editDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitEdit();
      });
    },
    async submitEdit() {
      console.log(this.editDialogForm)
      await this.$http.post("Doc/updateObj/", this.editDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.editDialogFormRef.resetFields();
          this.editDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    deleteConfirm(id) {
      this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteUser(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async deleteUser(id) {
      await this.$http.post("Doc/deleteObj/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    flexWidth(prop, tableData, title, num = 0) {
      if (tableData.length === 0) {//表格没数据不做处理
        return;
      }
      let flexWidth = 0;//初始化表格列宽

      flexWidth = this.$common.flexWidth(prop, tableData, title, num);
      return flexWidth + 'px';
    },
    //上传图片之前async
    beforeEditorUpload(res, file) {
      //显示上传动画
      this.quillUpdateImg = true;
      //  const res1 = await uploadImage()
      // console.log(res1,'=====');
      // this.$emit('before',res, file)
      console.log(res);
      console.log(file);
    },
    // 上传图片成功
    uploadEditorSuccess(res, file) {
      console.log("上传成功")
      // this.$emit('upload',res, file)
      console.log(res);
      console.log(file);
      if (res.Code != '200' || res.Obj == null || res.Obj == '') {
        return this.$message.error(res.Message);
      }
      //拼接出上传的图片在服务器的完整地址
      let imgUrl = this.rootUrl + res.Obj;
      let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
      console.log(type);
      // 获取富文本组件实例
      let quill = this.$refs.customQuillEditor.quill;
      console.log(quill);
      // 获取光标所在位置
      let length = quill.getSelection().index;
      // 插入图片||视频  res.info为服务器返回的图片地址
      if (type == 'mp4' || type == 'MP4') {
        quill.insertEmbed(length, 'video', imgUrl)
      } else {
        quill.insertEmbed(length, 'image', imgUrl)
      }
      // 调整光标到最后
      quill.setSelection(length + 1)
      //取消上传动画
      this.quillUpdateImg = false;
    },
    // 上传(文件)图片失败
    uploadEditorError(res, file) {
      console.log("res");
      console.log(res);
      console.log("file");
      console.log(file);
      //页面提示
      this.$message.error('上传图片失败')
      //取消上传动画
      this.quillUpdateImg = false;
    },
    //上传图片之前async
    beforeEditorUploadEdit(res, file) {
      //显示上传动画
      this.quillUpdateImg = true;
      //  const res1 = await uploadImage()
      // console.log(res1,'=====');
      // this.$emit('before',res, file)
      console.log(res);
      console.log(file);
    },
    // 上传图片成功
    uploadEditorSuccessEdit(res, file) {
      console.log("上传成功")
      // this.$emit('upload',res, file)
      console.log(res);
      console.log(file);
      if (res.Code != '200' || res.Obj == null || res.Obj == '') {
        return this.$message.error(res.Message);
      }
      //拼接出上传的图片在服务器的完整地址
      let imgUrl = this.rootUrl + res.Obj;
      let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
      console.log(type);
      // 获取富文本组件实例
      let quill = this.$refs.customQuillEditorEdit.quill;
      // 获取光标所在位置
      let length = quill.getSelection().index;
      // 插入图片||视频  res.info为服务器返回的图片地址
      if (type == 'mp4' || type == 'MP4') {
        quill.insertEmbed(length, 'video', imgUrl)
      } else {
        quill.insertEmbed(length, 'image', imgUrl)
      }
      // 调整光标到最后
      quill.setSelection(length + 1)
      //取消上传动画
      this.quillUpdateImg = false;
    },
    // 上传(文件)图片失败
    uploadEditorErrorEdit(res, file) {
      console.log("res");
      console.log(res);
      console.log("file");
      console.log(file);
      //页面提示
      this.$message.error('上传图片失败')
      //取消上传动画
      this.quillUpdateImg = false;
    },
    //上传组件返回的结果
    uploadResult: function (res) {
      this.uploadUrlPath = res;
    },
    openContent: function () {
      console.log(this.content)
    },
  },
};
</script>

<style lang="less" scoped>
.el-table .cell {
  white-space: pre-line;
}
</style>