<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索框区域 -->
      <el-row :gutter="20">
        <el-col :span="15">
          <div>
            <el-input placeholder="角色关键字" v-model="queryInfo.query" clearable @clear="query">
              <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-button type="primary" @click="addDialogVisible = true">添加角色</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="userRoleList" border stripe>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column :width="flexWidth('RoleName',userRoleList,'',20)" label="角色名称" prop="RoleName"></el-table-column>
        <el-table-column class="button_column" label="操作" width="280px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)" v-if="scope.row.RoleName!='系统管理员'">编辑</el-button>
            <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)" v-else disabled>编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)" v-if="scope.row.RoleName!='系统管理员'">删除</el-button>
            <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)" v-else disabled>删除</el-button>
            <el-button type="warning" size="mini" @click="showAuthDialog(scope.row.id)">权限分配</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <!-- 分页区域 -->
      <el-pagination v-if="!isMobile" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalRecordCount">
      </el-pagination>
      <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total,sizes,prev, pager, next" :total="totalRecordCount">
      </el-pagination>

      <el-dialog title="添加角色" :visible.sync="addDialogVisible" width="100%" :close="addDialogClose" :close-on-click-modal="false">
        <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="角色名称" prop="RoleName">
            <el-input v-model="addDialogForm.RoleName"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="编辑角色" :visible.sync="editDialogVisible" width="100%" :close="editDialogClose" :close-on-click-modal="false">
        <el-form :model="editDialogForm" :rules="editDialogFormRules" ref="editDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="角色名称" prop="RoleName">
            <el-input v-model="editDialogForm.RoleName"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="角色授权" :visible.sync="authDialogVisible" width="100%" :close="authDialogClose" :close-on-click-modal="false">
        <el-form :model="editDialogForm" ref="authDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="角色名称" prop="RoleName">
            <el-input v-model="authDialogForm.RoleName" disabled></el-input>
          </el-form-item>
          <el-form-item label="菜单列表">
            <el-tree :data="menuList" ref="tree" show-checkbox node-key="Id" default-expand-all :default-checked-keys="checkMenuNode" :props="defaultProps" @check="handleCheckChange">
            </el-tree>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="authDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAuth">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userRoleList: [],
      totalRecordCount: 0,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      addDialogVisible: false,
      editDialogVisible: false,
      authDialogVisible: false,
      addDialogForm: {
        RoleName: "",
      },
      editDialogForm: {
        RoleName: "",
        id: 0,
      },
      authDialogForm: {
        RoleName: "",
        id: 0,
      },
      menuList: [],

      checkMenuNode: [],
      defaultProps: {
        children: "ChildrenMenuList",
        label: "MenuName",
      },
      authMenuList: [],
      isMobile:false,

      addDialogFormRules: {
        RoleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
      },
      editDialogFormRules: {
        RoleName: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getUserRoleList();
    var isMobileTemp=window.sessionStorage.getItem("isMobile");
    if(isMobileTemp=='true'){
      this.isMobile=true;
    }else{
      this.isMobile=false;
    }
  },
  methods: {
    flexWidth(prop, tableData, title, num = 0) {
      if (tableData.length === 0) {//表格没数据不做处理
        return;
      }
      let flexWidth = 0;//初始化表格列宽
      
      flexWidth = this.$common.flexWidth(prop, tableData, title, num );
      return flexWidth + 'px';
    },
    /* 因为是返回的promise对象，所以用async及await */
    async getUserRoleList() {
      //this.$http.post("User/GetUserList", this.queryInfo)
      await this.$http
        .get("UserRole/GetUserRoleList", { params: this.queryInfo })
        .then(
          (res) => {
            if (res.data.Code !== "200")
              return this.$message.error(res.data.Message);
            this.userRoleList = res.data.Obj;
            this.totalRecordCount = res.data.TotalCount;
          },
          (res) => {
            this.$message.error("远程获取数据失败");
          }
        );
    },
    change(e) {
      this.$forceUpdate();
    },
    query() {
      this.getUserRoleList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getUserRoleList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getUserRoleList();
    },

    addDialogClose() {
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    saveAdd() {
      this.submitAdd();
    },
    async submitAdd() {
      await this.$http.post("UserRole/AddUserRole/", this.addDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.addDialogFormRef.resetFields();
          this.addDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    editDialogClose() {
      this.editDialogForm.roleId = "";
      this.editDialogVisible = false;
      this.$refs.editDialogFormRef.resetFields();
    },
    showEditDialog(id) {
      var userRole = null;
      for (var i = 0; i < this.userRoleList.length; i++) {
        if (this.userRoleList[i].id === id) userRole = this.userRoleList[i];
      }
      this.editDialogForm.RoleName = userRole.RoleName;
      this.editDialogForm.id = id;
      this.editDialogVisible = true;
    },
    saveEdit() {
      this.submitEdit();
    },
    async submitEdit() {
      await this.$http.post("UserRole/EditUserRole/", this.editDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.editDialogFormRef.resetFields();
          this.editDialogVisible = false;
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    deleteConfirm(id) {
      this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteUserRole(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async deleteUserRole(id) {
      await this.$http.post("UserRole/DeleteUserRole/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    showAuthDialog(id) {
      var userRole = null;
      for (var i = 0; i < this.userRoleList.length; i++) {
        if (this.userRoleList[i].id === id) userRole = this.userRoleList[i];
      }
      this.authMenuList.length=0;
      this.getMenu(id);
      this.authDialogForm.RoleName = userRole.RoleName;
      this.authDialogForm.id = id;
      this.authDialogVisible = true;
    },
    async getMenu(id) {
      await this.$http
        .get("Menu/GetAllMenuList/", { params: { roleid: id } })
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              return this.$message.error(res.data.Message);
            }
            this.menuList = res.data.Obj;
            var dataNode = [];
            for (var i = 0; i < this.menuList.length; i++) {
              for (var j = 0; j < this.menuList[i].ChildrenMenuList.length; j++) {
                if (this.menuList[i].ChildrenMenuList[j].IsYes == true) {
                  dataNode.push(this.menuList[i].ChildrenMenuList[j].Id);
                }
              }
            }
            this.checkMenuNode = dataNode;
            //console.log(dataNode);
          },
          (res) => {
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    authDialogClose() {
      this.authDialogForm.RoleName = "";
      this.authDialogVisible = false;
      this.$refs.authDialogFormRef.resetFields();
    },
    handleCheckChange(data, checked, indeterminate) {
      //console.log(data, checked);
      //console.log(checked.checkedKeys);
      // if (data.MenuGrade === 2) {
      //   var authRes = {
      //     id: 0,
      //     checkedOld: false,
      //     checkedNew: false,
      //   };
      //   authRes.id = data.Id;
      //   authRes.checkedOld = data.IsYes;
      //   authRes.checkedNew = checked;
      //   this.authMenuList.push(authRes);
      // }
      //console.log(this.$refs.tree.getCheckedKeys());
      this.authMenuList=checked.checkedKeys;
      console.log(this.authMenuList);
    },
    async saveAuth() {
      // if (this.authMenuList.length === 0)
      //   return this.$message.error("没有进行授权的改变数据");
      console.log(this.authMenuList);
      var dataSubmit = {
        list: this.authMenuList,
        roleid: this.authDialogForm.id,
      };
      //qs.stringify(params: {ids: [1,2,3],type: 1}, { indices: false })
      await this.$http.post("Menu/AuthMenu/", this.$qs.stringify(dataSubmit)).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.authDialogVisible = false;
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
</style>