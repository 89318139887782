<template>
  <div>

    <el-card>

      <!-- 搜索框区域 -->
      <el-row :gutter="20">
        <el-col :span="24">
          <div>
            <el-input placeholder="手机关键字" style="width:100px;" v-model="queryInfo.phonenumber" clearable @clear="query">
            </el-input>
            <el-date-picker v-model="queryInfo.monthDate" type="date" placeholder="选择日期" style="width: 140px" format="yyyy-MM-dd"></el-date-picker>
            <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="userList" border stripe table-layout='auto' class="tableAuto">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column width="100px" label="用户头像" prop="WxAvatarurl">
          <template slot-scope="scope">
            <img style="width:50px;height:50px;border-radius: 50%;" :src="rootUrl+scope.row.WxAvatarurl" />
          </template>
        </el-table-column>

        <el-table-column :width="flexWidth('WxName',userList,'',20)" label="微信名称" prop="WxName"></el-table-column>
        <el-table-column :width="flexWidth('PhoneNumber',userList,'',20)" label="手机号码" prop="PhoneNumber"></el-table-column>
        <el-table-column :width="flexWidth('UserGrade',userList,'',20)" label="用户级别" prop="UserGrade"></el-table-column>
        <el-table-column width="150px" label="是否社保" prop="IsSocailUser"></el-table-column>
        <el-table-column :width="flexWidth('PayDateTime',userList,'',20)" label="缴费日期" prop="PayDateTime"></el-table-column>
        <el-table-column width="150px" label="缴费金额" prop="PayValue"></el-table-column>
        <el-table-column :width="flexWidth('DealResult',userList,'',20)" label="处理进度" prop="DealResult"></el-table-column>
        <el-table-column class="button_column" label="操作" width="350px">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="showEditDialog(scope.row.id)">详细</el-button>
            <el-button type="success" size="mini" @click="showAddDialog(scope.row.id)" v-if="scope.row.PayDateTime!=null && scope.row.PayDateTime!=''">处理</el-button>
            <el-button type="success" size="mini" disabled v-else>处理</el-button>

          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <!-- <el-pagination v-if="!isMobile" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalRecordCount">
      </el-pagination>
      <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total,sizes,prev, pager, next" :total="totalRecordCount">
      </el-pagination> -->

      <el-dialog title="用户信息" :visible.sync="editDialogVisible" width="100%" :close="editDialogClose" :close-on-click-modal="false">
        <el-form :model="editDialogForm" label-width="100px" class="dialog_form">
          <el-form-item label="微信名称" prop="loginName">
            <el-input v-model="editDialogForm.CurWxUser.WxName" disabled></el-input>
          </el-form-item>
          <el-form-item label="注册时间" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.RegDateTime" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.PhoneNumber" disabled></el-input>
          </el-form-item>
          <el-form-item label="注册省份" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.Province" disabled></el-input>
          </el-form-item>
          <el-form-item label="注册城市" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.City" disabled></el-input>
          </el-form-item>
          <el-form-item label="注册县区" prop="userName">
            <el-input v-model="editDialogForm.CurWxUser.County" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户级别" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.UserGrade" disabled></el-input>
          </el-form-item>
          <el-form-item label="是否社保" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.IsSocailUser" disabled></el-input>
          </el-form-item>
          <el-form-item label="分享来源" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.ScanUserNewsId" disabled></el-input>
          </el-form-item>
          <el-form-item label="UP 用户" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.UpWxName" disabled></el-input>
          </el-form-item>
          <el-form-item label="UPUP用户" prop="phoneNumber">
            <el-input v-model="editDialogForm.CurWxUser.UpUpWxName" disabled></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClose">关 闭</el-button>
        </span>
      </el-dialog>

      <el-dialog title="受理结果" :visible.sync="addDialogVisible" width="100%" :close="addDialogClose" :close-on-click-modal="false">
        <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="微信名称" prop="WxName">
            <el-input v-model="addDialogForm.WxName" disabled></el-input>
          </el-form-item>
          <el-form-item label="受理结果" prop="DealResult">
            <el-input v-model="addDialogForm.DealResult" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="PhoneNumber">
            <el-input v-model="addDialogForm.PhoneNumber" disabled></el-input>
          </el-form-item>
          <el-form-item label="处理结果" :required="true">
            <el-select v-model="addDialogForm.SelectedDealResult" class="form-select" clearable filterable placeholder="请选择处理结果">
              <el-option label="等待受理" value="等待受理"></el-option>
              <el-option label="已经受理" value="已经受理"></el-option>
              <el-option label="已经缴纳" value="已经缴纳"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </el-dialog>


    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rootUrl: '',
      userList: [],
      totalRecordCount: 0,
      queryInfo: {
        phonenumber: "",
        issocial: "",
        pagenum: 1,
        pagesize: 5,
        code: '',
        monthDate: ''
      },
      addDialogVisible: false,
      editDialogVisible: false,
      authDialogVisible: false,
      
      addDialogForm: {
        WxName: "",
        PhoneNumber: "",
        PayNewsId: "",
        DealResult: "",
        SelectedDealResult: "",
      },
      editDialogForm: {
        CurWxUser: [],
      },
      authDialogForm: {
        WxName: "",
        PhoneNumber: "",
        UserGrade: "",
        NewsId: "",
        UpNewsId: "",
        UpUpNewsId: "",
        selectedWxName: "",
        selectedPhoneNumber: "",
        selectedUserGrade: "",
        key: "",
      },
      uploadDialogForm: {
        newsid: '',
      },
      userGradeList: [],
      isMoblile: false,
      currentRow: null,
      //fileList: [{ name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100', id: '1' }, { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }],
      fileList: [],
      fileListBus: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    };
  },
  created() {

    var isMobileTemp = window.sessionStorage.getItem("isMobile");
    if (isMobileTemp == 'true') {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.rootUrl = window.sessionStorage.getItem("rootUrl");
    this.queryInfo.code = window.sessionStorage.getItem("phone");
    this.queryInfo.monthDate = this.$common.getCurrentDate();
    this.getUserList();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      alert("bbb")
      console.log(res)
    },
    beforeAvatarUpload(file) {

      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
        return;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
        return;
      }
      //return (isJPG || isPNG) && isLt2M;
      this.uploadFile(file)
    },
    handleRemove(file, fileList) {
      //console.log(file, fileList);
      this.$confirm("您确实要删除吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.removeExe(file.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          this.getAgreementListByUser('劳动合同');
        });
    },
    async removeExe(id) {
      await this.$http.post("WxUser/deleteAgreementFile/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getAgreementListByUser('劳动合同');
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    handlePreview(file) {
      //console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async uploadFile(file) {
      let formData = new FormData()
      //let file = item.raw
      //console.log(item)
      formData.append('newsid', this.uploadDialogForm.newsid);
      formData.append('imgtype', '劳动合同')
      formData.append('file', file)
      await this.$http.post("WxUser/UploadAgreement/", formData, {
        'Content-type': 'multipart/form-data'
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getAgreementListByUser('劳动合同');
          //this.getAgreementListByUser('业务协议');
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    handleAvatarSuccessBus(res, file) {
      console.log(res)
    },
    beforeAvatarUploadBus(file) {

      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;
 
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
        return;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
        return;
      }
      //return (isJPG || isPNG) && isLt2M;
      this.uploadFileBus(file)
    },
    handleRemoveBus(file, fileList) {
      //console.log(file, fileList);
      this.$confirm("您确实要删除吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.removeExeBus(file.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          this.getAgreementListByUser('业务协议');
        });
    },
    async removeExeBus(id) {
      await this.$http.post("WxUser/deleteAgreementFile/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getAgreementListByUser('业务协议');
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    handlePreviewBus(file) {
      //console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async uploadFileBus(file) {
      let formData = new FormData()
      //let file = item.raw
      //console.log(item)
      formData.append('newsid', this.uploadDialogForm.newsid);
      formData.append('imgtype', '业务协议')
      formData.append('file', file)
      await this.$http.post("WxUser/UploadAgreement/", formData, {
        'Content-type': 'multipart/form-data'
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getAgreementListByUser('业务协议');
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    async getAgreementListByUser(type) {
      var paramVar = this.$qs.stringify({
        ImgType: type,
        WxUserNewsId: this.uploadDialogForm.newsid,
      });
      await this.$http.post("WxUser/getAgreementListBySocial", paramVar).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);

          if (res.data.Obj != null && res.data.Obj.length > 0) {
            if (type == '劳动合同') this.fileList.length = 0;
            if (type == '业务协议') this.fileListBus.length = 0;
            for (var i = 0; i < res.data.Obj.length; i++) {
              var tempObj = new Object();
              tempObj.id = res.data.Obj[i].id;
              tempObj.name = "";
              tempObj.url = this.rootUrl + res.data.Obj[i].ImgUrl;
              if (type == '劳动合同') this.fileList.push(tempObj);
              if (type == '业务协议') this.fileListBus.push(tempObj);

            }
          }
          //console.log(this.fileList)
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },

    rowClick(row) {
      this.authDialogForm.UpNewsId = row.NewsId;
      this.authDialogForm.UpUpNewsId = row.UpNewsId;
      this.authDialogForm.selectedWxName = row.WxName;
      this.authDialogForm.selectedPhoneNumber = row.PhoneNumber;
      this.authDialogForm.selectedUserGrade = row.UserGrade;
    },
    /* 因为是返回的promise对象，所以用async及await */
    async getUserList() {
      //this.$http.post("User/GetUserList", this.queryInfo)
      var paramVar = this.$qs.stringify({
        code: this.queryInfo.code,
        phonenumber: this.queryInfo.phonenumber,
        monthdate: this.queryInfo.monthDate,
      });
      await this.$http.post("WxUser/getAllListByPage", paramVar).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.userList = res.data.Obj;
          this.totalRecordCount = res.data.TotalCount;
        },
        (res) => {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    change(e) {
      this.$forceUpdate();
    },
    query() {
      this.getUserList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getUserList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getUserList();
    },
    async getUserGradeList() {
      await this.$http.post("WxUser/GetList/", {
        phonenumber: this.authDialogForm.key,
        usergrade: '1'
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.userGradeList = res.data.Obj;
          this.authDialogForm.UpNewsId = "";
          this.authDialogForm.UpUpNewsId = "";
          this.authDialogForm.selectedWxName = "";
          this.authDialogForm.selectedPhoneNumber = "";
          this.authDialogForm.selectedUserGrade = "";
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    uploadDialogClose() {
      this.uploadDialogVisible = false;
    },
    showUploadDialog(newsid) {
      this.uploadDialogForm.newsid = newsid;
      this.getAgreementListByUser('劳动合同');
      this.getAgreementListByUser('业务协议');
      this.uploadDialogVisible = true;

    },

    addDialogClose() {
      this.addDialogForm.roleId = "";
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    showAddDialog(id) {
      var user = null;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === id) user = this.userList[i];
      }
      this.addDialogForm.PayNewsId = user.PayNewsId;
      this.addDialogForm.WxName = user.WxName;
      this.addDialogForm.DealResult = user.DealResult;
      this.addDialogForm.PhoneNumber = user.PhoneNumber;
      this.addDialogVisible = true;
      this.addDialogForm.SelectedDealResult = "";
    },
    saveAdd() {
      if (this.addDialogForm.SelectedDealResult === "")
        return this.$message.error("请选择处理结果");
      if (this.addDialogForm.SelectedDealResult == this.addDialogForm.DealResult)
        return this.$message.error("处理结果相同，不需改变");
      this.submitAadd();
    },
    async submitAadd() {
      await this.$http.post("WxUser/updateWxUserPayStatus/", {
        newsid: this.addDialogForm.PayNewsId,
        operateinfo: this.addDialogForm.SelectedDealResult,
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.addDialogVisible = false;
          this.getUserList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    editDialogClose() {
      this.editDialogVisible = false;
    },
    showEditDialog(id) {
      var user = null;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === id) user = this.userList[i];
      }
      this.editDialogForm.CurWxUser = user;
      this.editDialogVisible = true;
    },
    showAuthDialog(id) {
      var user = null;
      for (var i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === id) user = this.userList[i];
      }
      this.authDialogForm.WxName = user.WxName;
      this.authDialogForm.PhoneNumber = user.PhoneNumber;
      this.authDialogForm.UserGrade = user.UserGrade;
      this.authDialogForm.NewsId = user.NewsId;
      this.authDialogVisible = true;
    },
    authDialogClose() {
      this.authDialogVisible = false;
    },
    saveAuth() {
      if (this.authDialogForm.UpNewsId === "")
        return this.$message.error("请选择绑定业务经理或加盟商");
      if (this.authDialogForm.UpNewsId == this.authDialogForm.NewsId)
        return this.$message.error("不能选择本人进行绑定");
      this.submitAuth();
    },
    async submitAuth() {
      await this.$http.post("WxUser/updateWxUserIsSocial/", {
        NewsId: this.authDialogForm.NewsId,
        UpNewsId: this.authDialogForm.UpNewsId,
        UpUpNewsId: this.authDialogForm.UpUpNewsId,
      }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.authDialogVisible = false;
          this.getUserList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    setUserGradeNewsId(upNewsId, upUpNewsId, wxName, phoneNumber, userGrade) {

      this.authDialogForm.UpNewsId = upNewsId;
      this.authDialogForm.UpUpNewsId = upUpNewsId;
      this.authDialogForm.selectedWxName = wxName;
      this.authDialogForm.selectedPhoneNumber = phoneNumber;
      this.authDialogForm.selectedUserGrade = userGrade;
    },
    flexWidth(prop, tableData, title, num = 0) {
      if (tableData.length === 0) {//表格没数据不做处理
        return;
      }
      let flexWidth = 0;//初始化表格列宽

      flexWidth = this.$common.flexWidth(prop, tableData, title, num);
      return flexWidth + 'px';
    },
  },
};
</script>

<style lang="less" scoped>
.el-table .cell {
  white-space: pre-line;
}

</style>